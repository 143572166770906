








































































import { Component, Vue } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import LsPagination from "@/components/ls-pagination.vue";
import ExportData from '@/components/export-data/index.vue'
import {
    apiNoticeLists,
    apiNoticeStatus,
    apiNoticeDel,
} from "@/api/marketing/notice";
import { RequestPaging } from "@/utils/util";
import { PageMode } from "@/utils/type";

@Component({
    components: {
        LsDialog,
        LsPagination,
        ExportData
    },
})
export default class HelpManage extends Vue {
    /** S Data **/

    apiNoticeLists = apiNoticeLists

    // 搜索表单
    searchForm = {
        name: "", // 公告标题
    };
    // 分页
    pager: RequestPaging = new RequestPaging();

    /** E Data **/

    /** S Methods **/
    // 搜索
    onSearch() {
        this.getNoticeList();
    }

    // 重置搜索
    resetSearch() {
        this.$set(this.searchForm, "name", "");
        this.getNoticeList();
    }

    // 更改显示开关状态
    changeShowSwitchStatus(value: 0 | 1, data: any) {
        apiNoticeStatus({
            id: data.id,
            status: value,
        }).then(() => {
            this.getNoticeList();
        });
    }

    // 新增公告文章
    goNoticeAdd() {
        this.$router.push({
            path: "/notice/notice_edit",
            query: {
                mode: PageMode["ADD"],
            },
        });
    }

    // 编辑公告文章
    goNoticeEdit(data: any) {
        this.$router.push({
            path: "/notice/notice_edit",
            query: {
                id: data.id,
                mode: PageMode["EDIT"],
            },
        });
    }

    // 获取公告管理列表
    getNoticeList() {
        this.pager
            .request({
                callback: apiNoticeLists,
                params: {
                    name: this.searchForm.name,
                },
            })
            .catch(() => {
                this.$message.error("数据请求失败，刷新重载!");
            });
    }

    // 删除公告文章
    onNoticeDel(data: any) {
        apiNoticeDel({ id: data.id }).then(() => {
            this.getNoticeList();
        });
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getNoticeList();
    }

    /** E Life Cycle **/
}
